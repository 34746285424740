import React, { useEffect, useRef, useState } from "react";
import Button from "../../common/button";

import ActiveIcon from "../../assets/images/icons/iconAction.svg";
import star from "../../assets/images/icons/star.svg";
import Primary from "../../assets/images/SetPrimary.png";
import deleteUser10 from "../../assets/images/deleteUser.svg";
import deleteUser123 from "../../assets/images/Disapproved.png";
import Cross from "../../assets/images/Cross.png";
import assign from "../../assets/images/Unassign.png";
import Search from "../../assets/images/icons/SearchIcon.svg";
import clearFilter from "../../assets/images/icons/Clear-Filter-Icon-White.svg";
import NotificationImage from "../../assets/images/icons/Notification-icon.svg";
import shorting from "../../assets/images/icons/shorting.svg";
import Grid from "../../common/grid";
import Input from "../../common/input";
import DataTable from "react-data-table-component";
import { RotateLoader } from "react-spinners";
import Modal from "../../common/model";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  changePrimaryByUserId,
  deleteUserByUserId,
  getUserListByDealerId,
  userDetailsById,
  updateUserDetailsById,
  UserDetailAccount,
} from "../../services/userServices";
import Select from "../../common/select";
import { getCustomerUsersById } from "../../services/customerServices";
import { useMyContext } from "../../context/context";
import make from "../../assets/images/star.png";
import edit from "../../assets/images/edit-text.png";
import delete1 from "../../assets/images/delete.png";
import AddItem from "../../assets/images/icons/addItem.svg";

import Headbar from "../../common/headBar";
import terms from "../../assets/images/Dealer/Address.svg";
import dealer from "../../assets/images/Dealer/Name.svg";
import RadioButton from "../../common/radio";
import Tabs from "../../common/tabs";
import PasswordInput from "../../common/passwordInput";
import {
  addSuperAdminMembers,
  changePasswordbyToken,
  getSuperAdminMembers,
  getUserNotificationData,
  updateNotificationData,
} from "../../services/extraServices";
import Card from "../../common/card";
import SingleView from "../../common/singleView";
import InActiveButton from "../../common/inActiveButton";
import CollapsibleDiv from "../../common/collapsibleDiv";
import SwitchButton from "../../common/switch";
import { Notifications } from "../../notificationjson";

function DealerUser() {
  const { toggleFlag } = useMyContext();
  const [selectedAction, setSelectedAction] = useState(null);
  const [userList, setUserList] = useState([]);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [loginDetails, setLoginDetails] = useState([]);
  const [firstMessage, setFirstMessage] = useState("");
  const [secondMessage, setSecondMessage] = useState("");
  const [isModalOpen, SetIsModalOpen] = useState(false);
  const [isprimary, SetIsprimary] = useState(false);
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);
  const [primaryText, SetPrimaryText] = useState("");
  const [secondaryText, SetSecondaryText] = useState("");
  const [title, setTitle] = useState('');
  const [mainStatus, setMainStatus] = useState(true);
  const [notificationSettings, setNotificationSettings] = useState({});
  const [notificationList, setNotificationList] = useState(Notifications)
  const [notification, setNotification] = useState({})
  const [servicerStatus, setServiceStatus] = useState(true);
  const [deleteId, setDeleteId] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [timer, setTimer] = useState(3);
  const dropdownRef = useRef(null);
  const [primary, setPrimary] = useState(false);
  const [createAccountOption, setCreateAccountOption] = useState("yes");
  const [activeIndex, setActiveIndex] = useState(null);
  const [showdata, setShowdata] = useState(true);
  const [isModalOpen12, setIsModalOpen12] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({
    lastName: "",
    firstName: "",
    phoneNumber: "",
    position: "",
    status: true,
    id: "",
  });
  // console.log("toggleFlag", toggleFlag);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const getUserList = async () => {
    setLoading1(true);
    const result = await getSuperAdminMembers();
    console.log(result.result);
    setUserList(result.result);
    setPrimary(result.loginMember.isPrimary);

    console.log(
      result.loginMember.isPrimary,
      "--------------------------------------res.result.loginMember>>>"
    );
    setLoading1(false);
  };
  const getLoginUser = async () => {
    const result = await UserDetailAccount("", {});
    console.log(result.result, "------------------Login--------------->>>>");
    setLoginDetails(result.result);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Close the dropdown if the click is outside of it
      setSelectedAction(null);
    }
  };
  useEffect(() => {
    getUserList();
    getLoginUser();
  }, []);

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    console.log(selectedValue);
    userValues.setFieldValue("status", selectedValue === "yes" ? true : false);
    setCreateAccountOption(selectedValue);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    let intervalId;

    if ((isModalOpen || (isModalOpen12 && timer > 0)) && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      closeModal1();
      setSelectedAction(null);
      closeModal();
      closeModal12();
      getUserList();
    }

    if (!isModalOpen && !isModalOpen12) {
      clearInterval(intervalId);
      setTimer(3);
    }

    setLoading(false);

    return () => {
      clearInterval(intervalId);
    };
  }, [isModalOpen, isModalOpen12, timer]);

  const closeModal = () => {
    SetIsModalOpen(false);
  };

  const openModal = () => {
    SetIsModalOpen(true);
    getUserList();
  };

  const handleSelectAll = (sectionKey, apiFieldName) => {
    console.log(title, apiFieldName);
    setNotificationSettings((prevSettings) => {
      const updatedSettings = { ...prevSettings };
      const section = Notifications.find((notification) => notification.index === sectionKey);

      if (section && section.sections) {
        section.sections.forEach(({ action, actionKey }) => {
          const actionKeyToUse = actionKey || action;
          updatedSettings[actionKeyToUse] = true;
        });
      }

      console.log(updatedSettings);

      return updatedSettings;
    });
    setNotification((prevNotifications) =>
      toggleAllActionsInSection(prevNotifications, apiFieldName, true)
    );
  };

  const toggleAllActionsInSection = (notifications, apiFieldName, value) => {
    const updatedNotifications = { ...notifications };
    console.log(notifications, apiFieldName)

    if (updatedNotifications.notifications[apiFieldName]) {
      const section = updatedNotifications.notifications[apiFieldName];
      for (const key in section) {
        if (key !== "_id" && typeof section[key] === "boolean") {
          section[key] = value;
        }
      }
    }
    updateNotificationData(updatedNotifications._id, updatedNotifications.notifications).then((res) => {
      console.log(res)
    })
    console.log(updatedNotifications)
    return updatedNotifications;
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match groups of 3 digits

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber; // Return original phone number if it couldn't be formatted
  };

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const handleSelectChange = async (label, value) => {
    formik1.setFieldValue(label, value);
  };
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };
  const openModal1 = (id) => {
    setDeleteId(id);
    setIsModalOpen1(true);
  };
  const status = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const closeModal2 = () => {
    setIsModalOpen2(false);
    formik.resetForm();
  };
  const openModal2 = () => {
    setIsModalOpen2(true);
  };

  const openUserModal = () => {
    setInitialFormValues({
      lastName: "",
      firstName: "",
      phoneNumber: "",
      position: "",
      status: true,
      id: "",
    });
    setIsUserModalOpen(true);
  };

  const closeUserModal = () => {
    setIsUserModalOpen(false);
    setInitialFormValues({
      lastName: "",
      firstName: "",
      phoneNumber: "",
      position: "",
      status: true,
      id: "",
    });
    userValues.resetForm();
  };

  const closeModal12 = () => {
    setIsModalOpen12(false);
  };
  const openModal12 = () => {
    setIsModalOpen12(true);
  };
  const handleStatusChange = async (row, newStatus) => {
    console.log(row);
    try {
      setUserList((userData) => {
        return userData.map((user) => {
          if (user._id === row._id) {
            return {
              ...user,
              status: newStatus === "active" ? true : false,
            };
          }
          return user;
        });
      });

      const result = await updateUserDetailsById({
        id: row._id,
        status: newStatus === "active" ? true : false,
      });

      console.log(result);
    } catch (error) {
      console.error("Error in handleStatusChange:", error);
    }
  };
  const emailValidationRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Required")
        .transform((originalValue) => originalValue.trim())
        .max(30, "Must be exactly 30 characters"),
      lastName: Yup.string()
        .required("Required")
        .transform((originalValue) => originalValue.trim())
        .max(30, "Must be exactly 30 characters"),
      email: Yup.string()
        .required("Required")
        .matches(emailValidationRegex, "Invalid email address")
        .transform((originalValue) => originalValue.trim()),
      phoneNumber: Yup.string()
        .required("Required")
        .min(10, "Must be at least 10 characters")
        .max(10, "Must be exactly 10 characters")
        .matches(/^[0-9]+$/, "Must contain only digits"),
      status: Yup.boolean().required("Required"),
    }),
    onSubmit: async (values) => {
      setEditLoading(true);
      console.log("Form values:", values);
      const result = await userDetailsById(values);
      console.log(result);
      if (result.code == 200) {
        setEditLoading(false);
        SetPrimaryText("User Updated Successfully ");
        SetSecondaryText("user Updated successfully ");
        openModal();
        toggleFlag();
        setTimer(3);
        getUserList();
      } else {
        SetPrimaryText("Error ");
        SetSecondaryText(result.message);
        SetIsModalOpen(true);
        setTimer(3);
        setEditLoading(false);
      }
      closeModal2();
    },
  });

  const calculateDropdownPosition = (index) => {
    const isCloseToBottom = userList.length - index <= 10000;
    return isCloseToBottom ? "bottom-[1rem]" : "top-[1rem]";
  };

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
  };

  const deleteUser = async () => {
    const result = await deleteUserByUserId(deleteId);
    console.log(result);
    if (result.code === 200) {
      getUserList();
      setIsModalOpen12(true);
      // closeModal1();
    }
  };
  const formik1 = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Required")
        .transform((originalValue) => originalValue.trim())
        .max(30, "Must be exactly 30 characters"),
      lastName: Yup.string()
        .required("Required")
        .transform((originalValue) => originalValue.trim())
        .max(30, "Must be exactly 30 characters"),
      phoneNumber: Yup.string()
        .required("Required")
        .min(10, "Must be at least 10 characters")
        .max(10, "Must be exactly 10 characters")
        .matches(/^[0-9]+$/, "Must contain only digits"),
      status: Yup.boolean().required("Required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const result = await updateUserDetailsById(values);
      console.log(result);
      if (result.code == 200) {
        setLoading(false);
        SetPrimaryText("User Updated Successfully ");
        SetSecondaryText("user updated successfully ");
        // setFirstMessage("User Updated Successfully ");
        // setSecondMessage("user Updated successfully ");
        openModal();
        setTimer(3);
        filterUserDetails();
      } else {
        setLoading(false);
      }
      closeModal2();
    },
  });

  const userValues = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("Required")
        .transform((originalValue) => originalValue.trim())
        .max(30, "Must be exactly 30 characters"),
      lastName: Yup.string()
        .required("Required")
        .transform((originalValue) => originalValue.trim())
        .max(30, "Must be exactly 30 characters"),
      email: Yup.string()
        .required("Required")
        .matches(emailValidationRegex, "Invalid email address")
        .transform((originalValue) => originalValue.trim()),
      phoneNumber: Yup.string()
        .required("Required")
        .min(10, "Must be at least 10 characters")
        .max(10, "Must be exactly 10 characters")
        .matches(/^[0-9]+$/, "Must contain only digits"),
      status: Yup.boolean().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log("Form values:", values);
      setAddLoading(true);
      const result = await addSuperAdminMembers(values);
      console.log(result);
      if (result.code == 200) {
        setAddLoading(false);
        setTimer(3);
        SetPrimaryText("User Add Successfully ");
        SetSecondaryText("user Add successfully ");
        closeUserModal();

        SetIsModalOpen(true);
        setIsUserModalOpen(false);
        getUserList();
        userValues.resetForm();
      } else {
        setAddLoading(false);
        if (result.code === 401) {
          userValues.setFieldError("email", "Email already in use");
        }
        // closeUserModal()
        // setLoading(false)
      }
      closeModal2();
    },
  });

  const editUser = async (id) => {
    console.log(id);
    const result = await userDetailsById(id);
    console.log(result.result.status);
    SetIsprimary(result.result.isPrimary);
    setMainStatus(result.mainStatus);
    setInitialFormValues({
      id: id,
      lastName: result?.result?.lastName,
      firstName: result?.result?.firstName,
      phoneNumber: result?.result?.phoneNumber,
      position: result?.result?.position,
      status: result?.result?.status,
    });
    setIsUserModalOpen(false);
    openModal2();
  };

  const makeUserPrimary = async (row) => {
    console.log(row._id);
    const result = await changePrimaryByUserId(row._id);
    console.log(result);
    if (result.code === 200) {
      SetPrimaryText("It's set to Primary");
      SetSecondaryText("We have successfully made this user primary");
      toggleFlag();
      openModal();
    }
  };

  const filterUserDetails = async (value) => {
    try {
      // setLoading1(true);
      const res = await getSuperAdminMembers(value);
      setUserList(res.result);
      let local = JSON.parse(localStorage.getItem('userDetails'));
      // localStorage.removeItem('userDetails')
      local.userInfo = {
        lastName: res?.loginMember?.lastName,
        firstName: res?.loginMember?.firstName,
      }
      localStorage.setItem('userDetails', JSON.stringify(local))
      console.log(local, '---------------')
    } catch (error) {
      console.error("Error fetching category list:", error);
    } finally {
      setLoading1(false);
    }
  };

  const handleFilterIconClick = () => {
    formikUSerFilter.resetForm();
    console.log(formikUSerFilter.values);
    getUserList();
  };
  const formikUSerFilter = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string(),
      lastName: Yup.string(),
      email: Yup.string(),
      phone: Yup.number(),
    }),
    onSubmit: async (values) => {
      filterUserDetails(values);
    },
  });
  const columns = [
    {
      name: "Name",
      selector: "name",
      // sortable: true,
      cell: (row) => (
        <div className="flex relative">
          {row.isPrimary && (
            <img src={star} alt="" className="absolute -left-3 top-0" />
          )}
          <span className="self-center pt-2 ml-3">
            {row.firstName} {row.lastName}
          </span>
        </div>
      ),
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
      // sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => "+1 " + formatPhoneNumber(row.phoneNumber),
      // sortable: true,
    },
    {
      name: "Position",
      selector: (row) => row.position,
      // sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      // sortable: true,
      cell: (row) => (
        <div className="relative">
          <div
            className={` ${row.status === true ? "bg-[#6BD133]" : "bg-[#FF4747]"
              } absolute h-3 w-3 rounded-full top-[33%] ml-[8px]`}
          ></div>
          <select
            disabled={row.isPrimary || !servicerStatus}
            value={row.status === true ? "active" : "inactive"}
            onChange={(e) => handleStatusChange(row, e.target.value)}
            className="text-[12px] border border-gray-300 text-[#727378] pl-[20px] py-2 pr-1 font-semibold rounded-xl"
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      ),
    },
    {
      name: "Action",
      minWidth: "auto", // Set a custom minimum width
      maxWidth: "90px", // Set a custom maximum width
      cell: (row, index) => {
        // console.log(index, index % 10 == 9)
        return (
          <div className="relative">
            <div
              onClick={() =>
                setSelectedAction(
                  selectedAction === row.email ? null : row.email
                )
              }
            >
              <img
                src={ActiveIcon}
                className="cursor-pointer	w-[35px]"
                alt="Active Icon"
              />
            </div>
            {selectedAction === row.email && (
              <SingleView
                ref={dropdownRef}
                className={`absolute z-[9999] ${!row.isPrimary ? "w-[140px]" : "w-[120px]"
                  } drop-shadow-5xl -right-3 mt-2 py-1 border rounded-lg shadow-md ${calculateDropdownPosition(
                    index
                  )}`}
              >
                {!row.isPrimary && row.status && (
                  <div
                    className="text-left cursor-pointer flex  py-1 px-2 border-b"
                    onClick={() => makeUserPrimary(row)}
                  >
                    <div
                      style={{
                        maskImage: `url(${make})`,
                        WebkitMaskImage: `url(${make})`,
                        maskRepeat: "no-repeat",
                        WebkitMaskRepeat: "no-repeat",
                        maskPosition: "center",
                        WebkitMaskPosition: "center",
                        maskSize: "contain",
                        WebkitMaskSize: "contain",
                      }}
                      className="self-center singleViews mr-2 h-4 w-4 "
                    />
                    {/* <img src={make} className="w-4 h-4 mr-2" />{" "} */}
                    <span className="self-center"> Make Primary </span>
                  </div>
                )}

                <div
                  className="text-left cursor-pointer flex  py-1 px-2 border-b"
                  onClick={() => editUser(row._id)}
                >
                  <div
                    style={{
                      maskImage: `url(${edit})`,
                      WebkitMaskImage: `url(${edit})`,
                      maskRepeat: "no-repeat",
                      WebkitMaskRepeat: "no-repeat",
                      maskPosition: "center",
                      WebkitMaskPosition: "center",
                      maskSize: "contain",
                      WebkitMaskSize: "contain",
                    }}
                    className="self-center singleViews mr-2 h-4 w-4 "
                  />
                  {/* <img src={edit} className="w-4 h-4 mr-2" />{" "} */}
                  <span className="self-center">Edit </span>
                </div>
                <div
                  onClick={() => openNotification(row._id)}
                  className={`text-left cursor-pointer flex ${!row.isPrimary && 'border-b'} py-1 px-2`}
                >
                  <div
                    style={{
                      maskImage: `url(${NotificationImage})`,
                      WebkitMaskImage: `url(${NotificationImage})`,
                      maskRepeat: "no-repeat",
                      WebkitMaskRepeat: "no-repeat",
                      maskPosition: "center",
                      WebkitMaskPosition: "center",
                      maskSize: "contain",
                      WebkitMaskSize: "contain",
                    }}
                    className="self-center singleViews mr-2 h-4 w-4 "
                  />
                  {/* <img src={edit} className="w-4 h-4 mr-2" />{" "} */}
                  <span className="self-center">Notification </span>
                </div>
                {!row.isPrimary && (
                  <div
                    className="text-left cursor-pointer flex py-1 px-2"
                    onClick={() => openModal1(row._id)}
                  >
                    <div
                      style={{
                        maskImage: `url(${delete1})`,
                        WebkitMaskImage: `url(${delete1})`,
                        maskRepeat: "no-repeat",
                        WebkitMaskRepeat: "no-repeat",
                        maskPosition: "center",
                        WebkitMaskPosition: "center",
                        maskSize: "contain",
                        WebkitMaskSize: "contain",
                      }}
                      className="self-center singleViews mr-2 h-4 w-4 "
                    />
                    {/* <img src={delete1} className="w-4 h-4 mr-2" />{" "} */}
                    <span className="self-center">Delete</span>
                  </div>
                )}
              </SingleView>
            )}
          </div>
        );
      },
    },
  ];

  const columns1 = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <div className="flex relative">
          {row.isPrimary && (
            <img src={star} alt="" className="absolute -left-3 top-0" />
          )}
          <span className="self-center pt-2 ml-3">
            {row.firstName} {row.lastName}
          </span>
        </div>
      ),
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => "+1 " + formatPhoneNumber(row.phoneNumber),
      sortable: true,
    },
    {
      name: "Position",
      selector: (row) => row.position,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div className="relative">
          <div
            className={` ${row.status === true ? "bg-[#6BD133]" : "bg-[#FF4747]"
              } absolute h-3 w-3 rounded-full top-[33%] ml-[8px]`}
          ></div>
          <select
            disabled={true}
            value={row.status === true ? "active" : "inactive"}
            onChange={(e) => handleStatusChange(row, e.target.value)}
            className="text-[12px] border border-gray-300 text-[#727378] pl-[20px] py-2 pr-1 font-semibold rounded-xl"
          >
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      ),
    },
  ];
  const initialValues2 = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    console.log(values);
    passwordChange(values);
    setSubmitting(false);
  };

  const passwordChange = async (value) => {
    setLoading1(true);
    delete value.confirmPassword;

    try {
      const res = await changePasswordbyToken(value);
      console.log(res);
      if (res.code == 200) {
        SetPrimaryText("Updated Password Successfully ");
        SetSecondaryText("User Password Updated  successfully ");
        openModal();
        toggleFlag();
        setTimer(3);
      } else {
        setLoading(false);
        setFirstMessage("Error ");
        setSecondMessage(res.message);
        setIsPasswordOpen(true);
      }
    } catch (error) {
      console.error("Error changing password:", error);
    } finally {
      setLoading1(false);
    }
    console.log(value);
  };

  const closePassword = () => {
    setIsPasswordOpen(false);
  };

  const passwordChnageForm = useFormik({
    initialValues: initialValues2,
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Required"),
      newPassword: Yup.string().required("Required"),
      confirmPassword: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
    }),
    onSubmit: handleSubmit,
  });

  const CustomNoDataComponent = () => (
    <Card className="text-center my-5">
      <p>No records found.</p>
    </Card>
  );


  const openNotification = async (id) => {
    const data = await getUserNotificationData(id);
    const notifications = data.result.notifications;
    setNotification(data.result)
    const mappedSettings = {};
    Object.keys(notifications).forEach((categoryKey) => {
      Object.keys(notifications[categoryKey]).forEach((subKey) => {
        if (typeof notifications[categoryKey][subKey] === "boolean") {
          mappedSettings[subKey] = notifications[categoryKey][subKey];
        }
      });
    });
    const newValue = notificationList.map((notification) => ({
      ...notification,
      sections: notification.sections.map((section) => ({
        ...section,
        isOn: mappedSettings[section.actionKey || section.action] || false,
      })),
    }));

    console.log('------------red', newValue)
    setNotificationSettings(mappedSettings);
    setIsNotificationOpen(true);
  };


  const handleAddOrUpdate1 = (actionKey) => {
    setNotificationSettings((prevSettings) => ({
      ...prevSettings,
      [actionKey]: !prevSettings[actionKey],
    }));

    setNotification((prevNotifications) =>
      toggleNotification(prevNotifications, actionKey)
    );
  };

  const toggleNotification = (prevNotifications, actionKey) => {
    const updatedNotifications = { ...prevNotifications };
    for (const category in updatedNotifications.notifications) {
      if (updatedNotifications.notifications[category] && typeof updatedNotifications.notifications[category] === "object") {
        if (actionKey in updatedNotifications.notifications[category]) {
          updatedNotifications.notifications[category][actionKey] = !updatedNotifications.notifications[category][actionKey];
          break;
        }
      }
    }
    updateNotificationData(updatedNotifications._id, updatedNotifications.notifications).then((res) => {
      console.log(res)
    })
    return updatedNotifications;
  };

  const closeNotification = () => {
    setIsNotificationOpen(false);
  }

  return (
    <>
      {/* {loading && (
        <div className=" fixed z-[999999] bg-[#333333c7] backdrop-blur-xl  h-screen w-full flex py-5">
          <div className="self-center mx-auto">
            <RotateLoader color="#fff" />
          </div>
        </div>
      )} */}
      {loading1 ? (
        <>
          <div className=" h-[500px] w-full flex py-5">
            <div className="self-center mx-auto">
              <RotateLoader color="#333" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="my-8">
            <Headbar />
            <div className="flex mt-2">
              <div className="pl-3">
                <p className="font-bold text-[36px] leading-9	mb-[3px]">
                  Manage Users
                </p>
                <ul className="flex self-center">
                  <li className="text-sm text-neutral-grey font-semibold ml-1 pt-[1px]">
                    {" "}
                    Edit Account{" "}
                  </li>
                </ul>
              </div>
            </div>
            <Card className="px-8 pb-8 pt-4 mt-5 mb-8 drop-shadow-4xl border-[1px] border-Light-Grey  rounded-xl relative">
              <SingleView className=" px-8 mt-8 py-8 rounded-[30px]">
                <Grid>
                  <div className="col-span-2 text-left">
                    <p className="text-base  font-semibold ">
                      {" "}
                      My Details
                    </p>
                  </div>
                  <div className="col-span-10 self-center">
                    <hr />
                  </div>
                </Grid>

                <Grid className="mx-auto mt-4">
                  <div className="col-span-2 self-center border-r border-[#4e4e4e]"></div>
                  <div className="col-span-3 border-r border-[#4e4e4e]">
                    <div className="flex">
                      <div className="self-center bg-[#FFFFFF08] rounded-xl mr-4">
                        <img src={dealer} className="" alt="dealer" width={50} height={50} />
                      </div>
                      <div className="self-center">
                        <p className=" text-base font-medium leading-5	">
                          Account Name
                        </p>
                        <p className=" opacity-50 text-sm	font-medium">
                          {loginDetails.name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-5">
                    <div className="flex justify-center">
                      <div className="self-center bg-[#FFFFFF08] rounded-xl mr-4">
                        <img src={terms} className="" alt="terms" width={50} height={50} />
                      </div>
                      <div className="self-center">
                        <p className=" text-base font-medium leading-5">
                          Address
                        </p>
                        <p className=" opacity-50	text-sm font-medium">
                          {loginDetails?.street}, {loginDetails?.city},{" "}
                          {loginDetails?.state} {loginDetails?.zip},{" "}
                          {loginDetails?.country}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-2"></div>
                </Grid>
              </SingleView>
              <Card className="px-8 pb-8 pt-4 mt-5 mb-8 drop-shadow-4xl border-[1px] border-Light-Grey  rounded-xl relative">
                <p className="text-xl font-semibold mb-5">Change Password</p>
                <form onSubmit={passwordChnageForm.handleSubmit}>
                  <Grid>
                    <div className="col-span-4">
                      <PasswordInput
                        type="password"
                        name="oldPassword"
                        label="Old Password"
                        value={passwordChnageForm.values.oldPassword}
                        onChange={passwordChnageForm.handleChange}
                        onBlur={passwordChnageForm.handleBlur}
                        isPassword
                        className="!bg-white"
                      />
                      {passwordChnageForm.touched.oldPassword &&
                        passwordChnageForm.errors.oldPassword && (
                          <div className="text-red-500">
                            {passwordChnageForm.errors.oldPassword}
                          </div>
                        )}
                    </div>

                    <div className="col-span-4">
                      <PasswordInput
                        type="password"
                        name="newPassword"
                        label="New Password"
                        isPassword
                        className="!bg-white"
                        value={passwordChnageForm.values.newPassword}
                        onChange={passwordChnageForm.handleChange}
                        onBlur={passwordChnageForm.handleBlur}
                      />
                      {passwordChnageForm.touched.newPassword &&
                        passwordChnageForm.errors.newPassword && (
                          <div className="text-red-500">
                            {passwordChnageForm.errors.newPassword}
                          </div>
                        )}
                    </div>
                    <div className="col-span-4">
                      <PasswordInput
                        type="password"
                        name="confirmPassword"
                        label="Confirm Password"
                        isPassword
                        className="!bg-white"
                        value={passwordChnageForm.values.confirmPassword}
                        onChange={passwordChnageForm.handleChange}
                        onBlur={passwordChnageForm.handleBlur}
                      />
                      {passwordChnageForm.touched.confirmPassword &&
                        passwordChnageForm.errors.confirmPassword && (
                          <div className="text-red-500">
                            {passwordChnageForm.errors.confirmPassword}
                          </div>
                        )}
                    </div>
                  </Grid>
                  <div className="mt-4 text-right">
                    <Button type="submit">Change Password</Button>
                  </div>
                </form>
              </Card>

              <Card className="px-8 pb-8 pt-4 mt-5 mb-8 drop-shadow-4xl border-[1px] border-Light-Grey  rounded-xl relative">
                {primary && (
                  <div className="bg-gradient-to-r from-[#f3f3f3] to-[#ededed] rounded-[20px] absolute top-[-17px] right-[-12px] p-3">
                    <Button onClick={() => openUserModal()}>
                      {" "}
                      + Add Member
                    </Button>
                  </div>
                )}

                <p className="text-xl font-semibold mb-3">Users List</p>

                <DataTable
                  columns={primary ? columns : columns1}
                  data={userList}
                  highlightOnHover
                  sortIcon={
                    <>
                      {" "}
                      <div
                        style={{
                          maskImage: `url(${shorting})`,
                          WebkitMaskImage: `url(${shorting})`,
                          maskRepeat: "no-repeat",
                          WebkitMaskRepeat: "no-repeat",
                          maskPosition: "center",
                          WebkitMaskPosition: "center",
                          maskSize: "contain",
                          WebkitMaskSize: "contain",
                        }}
                        className="ml-2 tabless"
                      />
                      {/* <img
                        src={shorting}
                        className="ml-2"
                        alt="shorting"
                      />{" "} */}
                    </>
                  }
                  noDataComponent={<CustomNoDataComponent />}
                />
              </Card>
            </Card>
          </div>
        </>
      )
      }

      {/* Modal Primary Popop */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="text-center py-3">
          <img src={Primary} alt="email Image" className="mx-auto" />
          <p className="text-3xl mb-0 mt-2 font-bold">
            {primaryText}
          </p>
          <p className="text-base font-medium mt-4">
            {secondaryText} <br />
            Redirecting Back to User List in {timer} Seconds
          </p>
        </div>
      </Modal>

      {/* Modal Delete Popop */}
      <Modal isOpen={isModalOpen1} onClose={closeModal1}>
        <div className="text-center py-3">
          <img src={assign} alt="email Image" className="mx-auto" />
          <p className="text-3xl mb-0 mt-2 font-bold ">
            Would you like to delete it?
          </p>
          <Grid className="!grid-cols-4 my-5 ">
            <div className="col-span-1"></div>
            <Button
              onClick={() => {
                deleteUser();
              }}
            >
              Yes
            </Button>
            <Button
              className="border w-full !border-Bright-Grey !bg-[white] !text-light-black !text-sm !font-Regular"
              onClick={() => closeModal1()}
            >
              No
            </Button>
            <div className="col-span-1"></div>
          </Grid>
        </div>
      </Modal>

      {/* Modal Delete Msg Popop */}
      <Modal isOpen={isModalOpen12} onClose={closeModal12}>
        <div className="text-center py-3">
          <img src={deleteUser10} alt="email Image" className="mx-auto" />
          <p className="text-3xl mb-0 mt-2 font-bold">
            Deleted Successfully
          </p>
          <p className="text-base font-medium mt-2">
            You have successfully deleted this user.
          </p>
          <p className="text-base font-medium mt-2">
            Redirecting Back to User List in {timer} seconds
          </p>
        </div>
      </Modal>

      {/* Modal Edit Popop */}
      <Modal isOpen={isUserModalOpen} onClose={closeUserModal}>
        {addLoading ?
          <div className="h-[400px] w-full flex py-5">
            <div className="self-center mx-auto">
              <RotateLoader color="#333" />
            </div>
          </div>
          :
          <div className=" py-3">
            <p className="text-3xl text-center mb-5 mt-2 font-semibold">
              Add New User
            </p>
            <form className="mt-8" onSubmit={userValues.handleSubmit}>
              <Grid className="px-8">
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="firstName"
                    label="First Name"
                    required={true}
                    className="!bg-white"
                    placeholder=""
                    maxLength={"30"}
                    value={userValues.values.firstName}
                    onBlur={userValues.handleBlur}
                    onChange={userValues.handleChange}
                    error={
                      userValues.touched.firstName && userValues.errors.firstName
                    }
                  />
                  {userValues.touched.firstName &&
                    userValues.errors.firstName && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {userValues.errors.firstName}
                      </div>
                    )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="lastName"
                    label="Last Name"
                    required={true}
                    placeholder=""
                    className="!bg-white"
                    maxLength={"30"}
                    value={userValues.values.lastName}
                    onBlur={userValues.handleBlur}
                    onChange={userValues.handleChange}
                    error={
                      userValues.touched.lastName && userValues.errors.lastName
                    }
                  />
                  {userValues.touched.lastName && userValues.errors.lastName && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {userValues.errors.lastName}
                    </div>
                  )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="email"
                    name="email"
                    label="Email"
                    className="!bg-white"
                    required={true}
                    placeholder=""
                    maxLength={"30"}
                    value={userValues.values.email}
                    onBlur={userValues.handleBlur}
                    onChange={userValues.handleChange}
                    error={userValues.touched.email && userValues.errors.email}
                  />
                  {userValues.touched.email && userValues.errors.email && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {userValues.errors.email}
                    </div>
                  )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="tel"
                    name="phoneNumber"
                    label="Phone"
                    required={true}
                    className="!bg-white"
                    placeholder=""
                    value={userValues.values.phoneNumber}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      console.log(sanitizedValue);
                      userValues.handleChange({
                        target: {
                          name: "phoneNumber",
                          value: sanitizedValue,
                        },
                      });
                    }}
                    onBlur={userValues.handleBlur}
                    onWheelCapture={(e) => {
                      e.preventDefault();
                    }}
                    minLength={"10"}
                    maxLength={"10"}
                    error={
                      userValues.touched.phoneNumber &&
                      userValues.errors.phoneNumber
                    }
                  />
                  {(userValues.touched.phoneNumber ||
                    userValues.submitCount > 0) &&
                    userValues.errors.phoneNumber && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {userValues.errors.phoneNumber}
                      </div>
                    )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="position"
                    label="Position"
                    className="!bg-white"
                    // required={true}
                    placeholder=""
                    maxLength={"30"}
                    value={userValues.values.position}
                    onBlur={userValues.handleBlur}
                    onChange={userValues.handleChange}
                    error={
                      userValues.touched.position && userValues.errors.position
                    }
                  />
                </div>
                <div className="col-span-6">
                  <p className=" flex text-[12px] font-semibold mt-3 mb-6">
                    Do you want to create an account?
                    <RadioButton
                      id="yes-create-account"
                      label="Yes"
                      value="yes"
                      checked={createAccountOption === "yes"}
                      onChange={handleRadioChange}
                    />
                    <RadioButton
                      id="no-create-account"
                      label="No"
                      value="no"
                      checked={createAccountOption === "no"}
                      onChange={handleRadioChange}
                    />
                  </p>
                </div>
              </Grid>
              <Grid className="!grid-cols-5 my-5  px-8">
                <div className="col-span-2">
                  <InActiveButton
                    className="border w-full !text-sm !font-Regular"
                    onClick={() => closeUserModal()}
                  >
                    Cancel
                  </InActiveButton>
                </div>

                <div className="col-span-3">
                  <Button type="submit" className="w-full">
                    Submit
                  </Button>
                </div>
              </Grid>
            </form>
          </div>
        }
      </Modal>

      <Modal isOpen={isModalOpen2} onClose={closeModal2}>
        {editLoading ?
          <div className="h-[400px] w-full flex py-5">
            <div className="self-center mx-auto">
              <RotateLoader color="#333" />
            </div>
          </div>
          :
          <div className=" py-3">
            <p className="text-3xl text-center mb-5 mt-2 font-semibold">
              Edit User
            </p>
            <form className="mt-8" onSubmit={formik1.handleSubmit}>
              <Grid className="px-8">
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="firstName"
                    label="First Name"
                    required={true}
                    className="!bg-white"
                    placeholder=""
                    maxLength={"30"}
                    value={formik1.values.firstName}
                    onBlur={formik1.handleBlur}
                    onChange={formik1.handleChange}
                    error={formik1.touched.firstName && formik1.errors.firstName}
                  />
                  {formik1.touched.firstName && formik1.errors.firstName && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik1.errors.firstName}
                    </div>
                  )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="lastName"
                    label="Last Name"
                    required={true}
                    placeholder=""
                    className="!bg-white"
                    maxLength={"30"}
                    value={formik1.values.lastName}
                    onBlur={formik1.handleBlur}
                    onChange={formik1.handleChange}
                    error={formik1.touched.lastName && formik1.errors.lastName}
                  />
                  {formik1.touched.lastName && formik1.errors.lastName && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik1.errors.lastName}
                    </div>
                  )}
                </div>
                <div className="col-span-6">
                  <Input
                    type="text"
                    name="position"
                    label="Position"
                    className="!bg-white"
                    placeholder=""
                    maxLength={"30"}
                    value={formik1.values.position}
                    onBlur={formik1.handleBlur}
                    onChange={formik1.handleChange}
                    error={formik1.touched.position && formik1.errors.position}
                  />
                </div>
                <div className="col-span-6">
                  <Input
                    type="tel"
                    name="phoneNumber"
                    label="Phone #"
                    required={true}
                    className="!bg-white"
                    placeholder=""
                    value={formik1.values.phoneNumber}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      console.log(sanitizedValue);
                      formik1.handleChange({
                        target: {
                          name: "phoneNumber",
                          value: sanitizedValue,
                        },
                      });
                    }}
                    onBlur={formik1.handleBlur}
                    onWheelCapture={(e) => {
                      e.preventDefault();
                    }}
                    minLength={"10"}
                    maxLength={"10"}
                    error={
                      formik1.touched.phoneNumber && formik1.errors.phoneNumber
                    }
                  />
                  {(formik1.touched.phoneNumber || formik1.submitCount > 0) &&
                    formik1.errors.phoneNumber && (
                      <div className="text-red-500 text-sm pl-2 pt-2">
                        {formik1.errors.phoneNumber}
                      </div>
                    )}
                </div>
                <div className="col-span-6">
                  <Select
                    label="Status"
                    required={true}
                    name="status"
                    placeholder=""
                    onChange={handleSelectChange}
                    disabled={isprimary}
                    className="!bg-white"
                    options={status}
                    value={formik1.values.status}
                    onBlur={formik1.handleBlur}
                    error={formik1.touched.status && formik1.errors.status}
                  />
                  {formik1.touched.status && formik1.errors.status && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {formik1.errors.status}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid className="!grid-cols-5 my-5  px-8">
                <div className="col-span-2">
                  <InActiveButton
                    className="border w-full !text-sm !font-Regular"
                    onClick={() => closeModal2()}
                  >
                    Cancel
                  </InActiveButton>
                </div>

                <div className="col-span-3">
                  <Button type="submit" className="w-full">
                    Submit
                  </Button>
                </div>
              </Grid>
            </form>
          </div>
        }
      </Modal>

      <Modal isOpen={isPasswordOpen} onClose={closePassword}>
        <Button
          onClick={closePassword}
          className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
        >
          <img
            src={Cross}
            className="w-full h-full text-black rounded-full p-0"
          />
        </Button>
        <div className="text-center py-3">
          <img src={deleteUser123} alt="email Image" className="mx-auto" />
          <p className="text-3xl mb-0 mt-2 font-bold">
            {firstMessage}
          </p>
          <p className="text-base font-medium mt-4">
            {secondMessage}
          </p>
        </div>
      </Modal>

      <Modal isOpen={isNotificationOpen} onClose={closeNotification} className="!w-[90%]">
        <Button
          onClick={closeNotification}
          className="absolute right-[-13px] top-0 h-[80px] w-[80px] !p-[19px] mt-[-9px] !rounded-full !bg-Granite-Gray"
        >
          <img src={Cross} className="w-full h-full text-black rounded-full p-0" />
        </Button>
        <div className="py-3">
          <p className="text-3xl font-bold text-center mb-5">Notification Settings</p>
          <div className="overflow-y-scroll min-h-[200px] max-h-[400px]">
            <Grid className="!grid-cols-2">
              {Object.entries(notificationList || []).map(([key, { index, title, sections, apiFieldName }], i) => (
                <div key={index} className="mb-4">
                  <CollapsibleDiv
                    key={index}
                    ShowData={showdata}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    imageClass="w-10 h-10"
                    className="!my-2"
                    index={index}
                    title={
                      <SingleView className="border-Gray28 border px-4 py-2 rounded-t-[22px]">
                        <p className="text-lg font-bold">{title}</p>
                      </SingleView>
                    }
                  >
                    <div className="px-4 pt-2 pb-4 border">
                      <div className="text-end ml-auto mb-2">
                        <Button type="button" className="!text-sm" onClick={() => handleSelectAll(index, apiFieldName)}>Select All</Button>
                      </div>
                      <Grid className="!grid-cols-12 !gap-2">
                        {sections.map(({ label, action }, itemIdx) => (

                          <div className="col-span-6" key={itemIdx}>
                            <Grid className="!gap-0">
                              <div className="col-span-8 self-center">
                                <p className="flex text-[12px] font-semibold justify-between">{label}</p>
                              </div>
                              <div className="col-span-4">
                                <SwitchButton
                                  isOn={notificationSettings[action] || false}
                                  handleToggle={() => handleAddOrUpdate1(action)}
                                />
                              </div>
                            </Grid>
                          </div>
                        ))}
                      </Grid>
                    </div>
                  </CollapsibleDiv>
                </div>
              ))}
            </Grid>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DealerUser;
