import React, { useEffect, useState } from "react";
import Headbar from "../../../common/headBar";
import { Link, useLocation } from "react-router-dom";
import Grid from "../../../common/grid";
import Button from "../../../common/button";

// Media Import
import all from "../../../assets/images/AciveAmount.svg";
import AllActive from "../../../assets/images/Amount.svg";
import wholesale from "../../../assets/images/AciveCount.svg";
import WholesaleActive from "../../../assets/images/Count.svg";
import SelectBoxWithSearch from "../../../common/selectBoxWIthSerach";
import { MultiSelect } from "react-multi-select-component";
import ClaimContent from "./Sale-Tab/ClaimContent";
import { useMyContext } from "./../../../context/context";
import { getFilterListForDealerClaim } from "../../../services/reportingServices";
import { RotateLoader } from "react-spinners";
import Card from "../../../common/card";
import { getUserDetailsFromLocalStorage } from "../../../services/extraServices";
import InActiveButton from "../../../common/inActiveButton";

function DealerClaims() {
  const location = useLocation();
  const isResellerReporting = location.pathname.includes("/reseller/sale");
  const getInitialActiveTab = () => {
    const storedTab = localStorage.getItem("ClaimMenu");
    return storedTab ? storedTab : "Amount";
  };

  const [activeTab, setActiveTab] = useState(getInitialActiveTab());
  const [selectedCat, setSelectedCat] = useState([]);
  const [categoryListCat, setCategoryListCat] = useState([]);
  const [priceBookListCat, setPriceBookListCat] = useState([]);
  const [categoryListServicer, setCategoryListServicer] = useState([]);
  const [priceBookListServicer, setPriceBookListServicer] = useState([]);
  const [servicerListServicer, setServicerListServicer] = useState([]);
  const [selectedSer, setSelectedSer] = useState([]);
  const [activeButton, setActiveButton] = useState("servicer");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [filterCategory, setFiltersCategory] = useState({
    priceBookId: [],
    servicerId: "",
    categoryId: "",
    primary: "category",
  });
  const [filterServicer, setFiltersServicer] = useState({
    priceBookId: [],
    servicerId: "",
    categoryId: "",
    primary: "servicer",
  });
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 14)),
    endDate: new Date(),
  });

  useEffect(() => {
    localStorage.setItem("ClaimMenu", activeTab);
  }, [activeTab]);

  const {
    setFiltersForClaimServicer,
    setFiltersForClaimCategory,
    toggleFilterFlag,
  } = useMyContext();

  const tabs = [
    {
      id: "Amount",
      label: "Amount",
      icons: all,
      className: "col-span-6",
      Activeicons: AllActive,
    },
    {
      id: "Count",
      label: "Count",
      className: "col-span-6",
      icons: wholesale,
      Activeicons: WholesaleActive,
    },
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const getDatasetAtEvent = async (data) => {
    try {
      // setLoading(true);
      const res = await getFilterListForDealerClaim(data);
      if (activeButton == "servicer") {

        const transformedServicer = res.result[0].servicer.map(servicer => ({
          label: servicer.name,
          value: servicer._id,
        }));
        const categoriesWithPriceBooks = res.result[0].categories.map((category) => ({
          label: category.categoryName,
          value: category.categoryId,
          priceBooks: category.priceBooks.map((priceBook) => ({
            label: priceBook.priceBookName,
            value: priceBook.priceBookId,
          })),
        }));
        const allPriceBooks = res.result[0].categories.flatMap((category) =>
          category?.priceBooks.map((priceBook) => ({
            label: priceBook?.priceBookName,
            value: priceBook?.priceBookId,
          }))
        );
        setCategoryListServicer(categoriesWithPriceBooks);
        setServicerListServicer(transformedServicer);
        setPriceBookListServicer(allPriceBooks)
      }
      else {

        const categoriesWithPriceBooks = [];
        const allPriceBooks = [];

        res.result[0].categories.forEach(category => {
          console.log(category)
          const priceBooks = (category.priceBooks || []).map(priceBook => {
            const priceBookObj = {
              label: priceBook.priceBookName,
              value: priceBook.priceBookId,
              categoryId: category.categoryId
            };
            allPriceBooks.push(priceBookObj);

            return priceBookObj;
          });
          categoriesWithPriceBooks.push({
            label: category.categoryName,
            value: category.categoryId,
            priceBooks
          });
        });

        setCategoryListCat(categoriesWithPriceBooks)
        setPriceBookListCat(allPriceBooks)
      }
    } catch (error) {
      console.error("Error fetching sales data:", error);
    }
    setLoading(false);
  };

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const handleFilterChangeCat = (name, value) => {
    setFiltersCategory(prev => ({
      ...prev,
      [name]: value
    }));
    // setLoading1(true);
    if (name === "categoryId") {
      const filteredCategory = categoryListCat.find(category => category.value === value);
      if (filteredCategory) {
        setPriceBookListCat(filteredCategory.priceBooks);
      }
      setSelectedCat([])
      handleFilterChangeCat('priceBookId', []);
    }
    if (name === "priceBookId" && value && filterCategory.categoryId) {
      const selectedValues = value.map(item => item.value);
      const matchingPriceBooks = priceBookListCat.filter(priceBook => selectedValues.includes(priceBook.value));
      setSelectedCat(matchingPriceBooks);

      setFiltersCategory(prev => ({
        ...prev,
        [name]: matchingPriceBooks.map(item => item.value)
      }));
    }

    // getDatasetAtEvent(updatedFilters);
    // setLoading1(false);
  };

  const handleFilterChangeServicer = (name, value) => {
    console.log()
    setLoading1(true);
    setFiltersServicer(prev => ({
      ...prev,
      [name]: value
    }));
    if (name === "categoryId") {
      const filteredCategory = categoryListServicer.find(category => category.value === value);
      if (filteredCategory) {
        setPriceBookListServicer(filteredCategory.priceBooks);
      }
      setSelectedSer([])
      handleFilterChangeServicer('priceBookId', []);
    }
    if (name === "priceBookId" && value) {
      const selectedValues = value.map(item => item.value);
      console.log(selectedValues, priceBookListServicer)
      const matchingPriceBooks = priceBookListServicer.filter(priceBook => selectedValues.includes(priceBook.value));
      // setSelectedCat(matchingPriceBooks);
      console.log(matchingPriceBooks)
      setFiltersServicer(prev => ({
        ...prev,
        [name]: matchingPriceBooks.map(item => item.value)
      }));
    }

    //  setFiltersServicer(updatedFilters);
    setLoading1(false);
  };

  useEffect(() => {
    getDatasetAtEvent(activeButton);
  }, [activeButton]);

  const handleApplyFilters = () => {
    // setLoading1(true);
    console.log(filterServicer, activeButton)
    if (activeButton == "category") {
      setFiltersForClaimCategory(filterCategory);
    } else if (activeButton == "servicer") {
      setFiltersForClaimServicer(filterServicer);

    }
    // setLoading1(false);
  };

  const handleResetFilters = () => {
    let data = {
      priceBookId: [],
      servicerId: "",
      categoryId: "",
      primary: activeButton,
    };
    if (activeButton === "category") {
      setFiltersCategory(data);
      setFiltersForClaimCategory(data);
    } else if (activeButton === "servicer") {
      setFiltersServicer(data);
      setFiltersForClaimServicer(data);
    }
    setSelectedCat([]);
    setSelectedSer([]);
    // getDatasetAtEvent(data);
  };
  const [buttonTextColor, setButtonTextColor] = useState('');
  const [backGroundColor, setBackGroundColor] = useState('');

  useEffect(() => {
    const storedUserDetails = getUserDetailsFromLocalStorage();

    if (storedUserDetails) {
      const colorScheme = storedUserDetails.colorScheme;
      colorScheme?.forEach(color => {
        switch (color.colorType) {
          case 'buttonColor':
            setBackGroundColor(color.colorCode);
            break;
          case 'buttonTextColor':
            setButtonTextColor(color.colorCode);
            break;
          default:
            break;
        }
      });
    }
  }, []);

  const InactiveTabButton = ({ tab, onClick }) => (
    <InActiveButton
      className="flex self-center mr-2 w-[95%] !px-2 !py-1 rounded-xl border-[1px] border-Light-Grey "
      onClick={onClick}
    >
      <div
        style={{
          maskImage: `url(${tab.icons})`,
          WebkitMaskImage: `url(${tab.icons})`,
          maskRepeat: "no-repeat",
          WebkitMaskRepeat: "no-repeat",
          maskPosition: "center",
          WebkitMaskPosition: "center",
          maskSize: "contain",
          WebkitMaskSize: "contain",
        }}
        className="self-center pr-1 py-1 h-4 w-4"
      />
      <span
        style={{
          borderLeftWidth: "1px",
          paddingLeft: "7px",
        }}
        className="ml-1 py-1 text-sm font-Regular"
      >
        {tab.label}
      </span>
    </InActiveButton>
  );

  // ActiveTabButton Component
  const ActiveTabButton = ({ tab, onClick }) => (
    <Button
      className="flex self-center mr-2 w-[95%] !px-2 !py-1 rounded-xl border-[1px] border-Light-Grey"
      onClick={onClick}
    >
      <div
        style={{
          maskImage: `url(${tab.Activeicons})`,
          WebkitMaskImage: `url(${tab.Activeicons})`,
          backgroundColor: buttonTextColor,
          maskRepeat: "no-repeat",
          WebkitMaskRepeat: "no-repeat",
          maskPosition: "center",
          WebkitMaskPosition: "center",
          maskSize: "contain",
          WebkitMaskSize: "contain",
        }}
        className="self-center pr-1 py-1 h-4 w-4"
      />
      <span
        style={{
          borderColor: buttonTextColor,
          borderLeftWidth: "1px",
          paddingLeft: "7px",
          color: buttonTextColor,
        }}
        className="ml-1 py-1 text-sm font-Regular"
      >
        {tab.label}
      </span>
    </Button>
  );
  return (
    <>
      {loading || loading1 ? <>
        <div className=" h-[400px] w-full flex py-5">
          <div className="self-center mx-auto">
            <RotateLoader color="#333" />
          </div>
        </div> </> :
        <div className="pb-8 mt-2 px-3 ">
          <Headbar />

          <div className="flex">
            <div className="pl-3 mb-4">
              <p className="font-bold text-[36px] leading-9 mb-[3px]">
                Reporting
              </p>
              <ul className="flex self-center">
                <li className="text-sm font-Regular">
                  <Link to={`${location.pathname.includes("/reseller/sale") ? '/reseller/dashboard' : '/dealer/dashboard'}`}>Home / </Link>{" "}
                </li>
                <li className="text-sm font-semibold ml-1 pt-[1px]">
                  {activeTab}
                </li>
              </ul>
            </div>
          </div>
          <Card className="p-3 mt-4">
            <div className="flex w-full mb-3">
              <p className="p-0 self-center font-bold mr-4">Filter By :</p>
              <div className="self-center">
                {activeButton === "servicer" ?
                  <>
                    <Button
                      onClick={() => handleButtonClick("servicer")}
                      className={`!rounded-e-[0px] !px-2 !py-1 !border-[#333333] !border-[1px] `}
                    >
                      Servicer
                    </Button>
                    <InActiveButton
                      onClick={() => handleButtonClick("category")}
                      className={`!rounded-s-[0px] !px-2 !py-1 !border-[#333333] !border-[1px] `}
                    >
                      Category
                    </InActiveButton>
                  </>
                  :
                  <>
                    <InActiveButton
                      onClick={() => handleButtonClick("servicer")}
                      className={`!rounded-e-[0px] !px-2 !py-1 !border-[#333333] !border-[1px] `}
                    >
                      Servicer
                    </InActiveButton>
                    <Button
                      onClick={() => handleButtonClick("category")}
                      className={`!rounded-s-[0px] !px-2 !py-1 !border-light-black !border-[1px]  `}
                    >
                      Category
                    </Button>
                  </>
                }


              </div>
            </div>
            <Grid
              className={`${activeButton === "dealer"
                ? "!grid-cols-10"
                : activeButton === "category"
                  ? "!grid-cols-6"
                  : "!grid-cols-8"
                } !gap-0`}
            >
              {activeButton === "category" && (
                <>
                  <div className="col-span-2 self-center pl-3">
                    <SelectBoxWithSearch
                      label="Category Name"
                      name="categoryId"
                      placeholder="Category Name"
                      value={filterCategory.categoryId}
                      className="!bg-white"
                      className1="filter"
                      options={categoryListCat}
                      pName="Category Name"
                      onChange={handleFilterChangeCat}
                    />
                  </div>
                  <div className="col-span-2 self-center pl-3 relative">
                    <MultiSelect
                      label="Dealer SKU"
                      name="priceBookId"
                      placeholder="Dealer SKU"
                      value={selectedCat}
                      options={priceBookListCat}
                      pName="Dealer SKU"
                      onChange={(value) => {
                        setSelectedCat(value);
                        handleFilterChangeCat("priceBookId", value);
                      }}
                      labelledBy="Select"
                      overrideStrings={{
                        selectSomeItems: "Select",
                      }}
                      className="SearchSelect css-b62m3t-container p-[0.425rem]"
                    />
                    <small className="absolute text-base font-Regular leading-6 duration-300 transform origin-[0] top-[12px] left-[25px] px-1 -translate-y-4 !hover:bg-grayf9 scale-75 !bg-white text-[#5D6E66]">
                      Dealer SKU
                    </small>
                  </div>
                  <div className="col-span-2 self-center ml-auto pl-3">
                    <Button className="mr-2" onClick={handleApplyFilters}>
                      Filter
                    </Button>
                    <InActiveButton
                      className=""
                      onClick={handleResetFilters}
                    >
                      Reset
                    </InActiveButton>
                  </div>
                </>
              )}
              {activeButton === "servicer" && (
                <>
                  <div className="col-span-2 self-center pl-3">
                    <SelectBoxWithSearch
                      label="Servicer Name"
                      name="servicerId"
                      value={filterServicer.servicerId}
                      onChange={handleFilterChangeServicer}
                      placeholder="Servicer Name"
                      className="!bg-white"
                      required={true}
                      className1="filter"
                      pName="Servicer Name"
                      options={servicerListServicer}
                    />
                  </div>

                  <div className="col-span-2 self-center pl-3">
                    <SelectBoxWithSearch
                      label="Category Name"
                      name="categoryId"
                      placeholder="Category Name"
                      value={filterServicer.categoryId}
                      className="!bg-white"
                      className1="filter"
                      options={categoryListServicer}
                      pName="Category Name"
                      onChange={handleFilterChangeServicer}
                    />
                  </div>
                  <div className="col-span-2 self-center pl-1 relative">
                    <MultiSelect
                      label="Dealer SKU"
                      name="priceBookId"
                      placeholder="Dealer SKU"
                      value={selectedSer}
                      options={priceBookListServicer}
                      pName="Dealer SKU"
                      onChange={(value) => {
                        setSelectedSer(value);
                        handleFilterChangeServicer("priceBookId", value);
                      }}
                      labelledBy="Select"
                      overrideStrings={{
                        selectSomeItems: "Select",
                      }}
                      className="SearchSelect css-b62m3t-container p-[0.425rem]"
                    />
                    <small className="absolute text-base font-Regular text-[#5D6E66] leading-6 duration-300 transform origin-[0] top-[12px] left-[17px] px-1 -translate-y-4 !hover:bg-grayf9 scale-75 !bg-white ">
                      Dealer SKU
                    </small>
                  </div>
                  <div className="col-span-2 self-center ml-auto pl-3">
                    <Button className="mr-2" onClick={handleApplyFilters}>
                      Filter
                    </Button>
                    <InActiveButton
                      onClick={handleResetFilters}
                    >
                      Reset
                    </InActiveButton>
                  </div>
                </>
              )}
            </Grid>
          </Card>

          <Grid className="!grid-cols-3">
            <div className="col-span-3">
              <Grid className="mt-2">
                <div className="col-span-5">
                  <div className="rounded-[30px] bg-white p-3 border-[1px] border-Light-Grey">
                    <Grid className="!gap-1 !grid-cols-2">
                      {tabs.map((tab) =>
                        activeTab === tab.id ? (
                          <ActiveTabButton key={tab.id} tab={tab} onClick={() => handleTabClick(tab.id)} />
                        ) : (
                          <InactiveTabButton key={tab.id} tab={tab} onClick={() => handleTabClick(tab.id)} />
                        )
                      )}
                    </Grid>
                  </div>
                </div>
                <div className="col-span-1 self-center"></div>
              </Grid>

              <ClaimContent
                activeTab={activeTab}
                selectedRange={selectedRange}
                activeButton={activeButton}
                setSelectedRange={setSelectedRange}
              />
            </div>
          </Grid>
        </div>
      }
    </>
  );
}

export default DealerClaims;