import React, { useEffect, useRef, useState } from "react";
import Headbar from "../../../common/headBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import Grid from "../../../common/grid";
import Button from "../../../common/button";

// Media Import
import BackImage from "../../../assets/images/icons/backArrow.svg";
import address from "../../../assets/images/Dealer/Address.svg";
import name from "../../../assets/images/Dealer/Name.svg";
import AddItem from "../../../assets/images/icons/addItem.svg";
import OrderActive from "../../../assets/images/Dealer/Order-active.svg";
import Order from "../../../assets/images/Dealer/Orders.svg";
import ContractsActive from "../../../assets/images/Dealer/Contract-active.svg";
import ClaimActive from "../../../assets/images/Dealer/Claim-active.svg";
import Claim from "../../../assets/images/Dealer/Claim.svg";
import ServicerActive from "../../../assets/images/Dealer/Servicer-active.svg";
import CustomerActive from "../../../assets/images/Dealer/Customer-active.svg";
import UserActive from "../../../assets/images/Dealer/User-active.svg";
import PriceBookActive from "../../../assets/images/Dealer/PriceBook-active.svg";
import Contract from "../../../assets/images/Dealer/Contract.svg";
import Servicer from "../../../assets/images/Dealer/Servicer.svg";
import Customer from "../../../assets/images/Dealer/Customers.svg";
import User from "../../../assets/images/Dealer/Users.svg";
import PriceBook from "../../../assets/images/Dealer/PriceBook.svg";
import email from "../../../assets/images/Dealer/Email.svg";
import phone from "../../../assets/images/Dealer/Phone.svg";
import OrderList from "./Dealer-Details/order";
import ServicerList from "./Dealer-Details/servicer";
import UserList from "./Dealer-Details/user";
import PriceBookList from "./Dealer-Details/priceBook";
import CustomerList from "./Dealer-Details/customer";
import Modal from "../../../common/model";
import shorting from "../../../assets/images/icons/shorting.svg";
import Input from "../../../common/input";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "../../../common/select";
import {
  createRelationWithDealer,
  editDealerData,
  getDealersDetailsByid,
  getDealersSettingsByid,
  uploadTermsandCondition,
} from "../../../services/dealerServices";
import { cityData } from "../../../stateCityJson";
import { RotateLoader } from "react-spinners";
import DataTable from "react-data-table-component";
import RadioButton from "../../../common/radio";
import {
  addUserByDealerId,
  checkUserToken,
  getUserListByDealerId,
} from "../../../services/userServices";
import Primary from "../../../assets/images/SetPrimary.png";
import { MyContextProvider, useMyContext } from "../../../context/context";
import { getServicerListForDealer } from "../../../services/servicerServices";
import Reseller from "./Dealer-Details/reseller";
import ContractList from "../Contract/contractList";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ClaimList from "../Claim/claimList";
import ClaimList12 from "./Dealer-Details/claim";
import Cross from "../../../assets/images/Cross_Button.png";
import Unpaid from "../../../assets/images/icons/Unpaid.svg";
import UnpaidActive from "../../../assets/images/icons/unpaidActive.svg";
import Paid from "../../../assets/images/icons/Paid.svg";
import ActivePaid from "../../../assets/images/icons/ActivePaid.svg";
import { getUserDetailsFromLocalStorage } from "../../../services/extraServices";
import { MultiSelect } from "react-multi-select-component";
import { getCovrageList } from "../../../services/priceBookService";
import Setting from "./Dealer-Details/setting";
import SingleView from "../../../common/singleView";
import InActiveButton from "../../../common/inActiveButton";
import Card from "../../../common/card";
function DealerDetails() {
  const getInitialActiveTab = () => {
    const storedTab = localStorage.getItem("menu");
    return storedTab ? storedTab : "Orders";
  };
  const id = useParams();
  const [activeTab, setActiveTab] = useState(getInitialActiveTab()); // Set the initial active tab
  // const id = useParams();
  // const [activeTab, setActiveTab] = useState("Orders"); // Set the initial active tab
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [refreshList, setRefreshUserList] = useState([]);
  const [scrolling, setScrolling] = useState(false);
  const [selected, setSelected] = useState([]);
  const [coverage, setCoverage] = useState([]);
  const [isStatus, setIsStatus] = useState(null);
  const [dealerDetails, setDealerDetails] = useState([]);
  const [dealerSettings, setDealerSettings] = useState({});
  const [createServicerAccountOption, setServicerCreateAccountOption] =
    useState(false);
  const [createAccountOption, setCreateAccountOption] = useState("yes");
  const [separateAccountOption, setSeparateAccountOption] = useState(true);
  const [firstMessage, setFirstMessage] = useState("");
  const [secondMessage, setSecondMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(3);
  const [servicerList, setServicerList] = useState([]);
  const [flagValue, setFlagValue] = useState(false);
  const navigate = useNavigate();
  const { servicerId } = useParams();
  const [createAccount, setCreateAccount] = useState(false);
  const [shipping, setShipping] = useState("yes");
  const inputRef = useRef(null);
  const [selectedFile2, setSelectedFile2] = useState("");
  const [initialUserFormValues, setInitialUserFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    position: "",
    status: createAccountOption,
    dealerId: id.id,
    isPrimary: false,
  });
  const { flag } = useMyContext();
  const [initialFormValues, setInitialFormValues] = useState({
    accountName: "",
    dealerId: "",
    street: "",
    city: "",
    zip: "",
    state: "",
    country: "USA",
    oldName: "",
    // serviceCoverageType: "",
    // coverageType: "",
    // isShippingAllowed: "",
    // isServicer: createServicerAccountOption,
    // isAccountCreate: createAccount,
    // userAccount: separateAccountOption,
    // termCondition: {
    //   fileName: "",
    //   name: "",
    //   size: "",
    // },
  });
  const [currentPage, setCurrentPage] = useState(1); // Tracks current page
  const [rowsPerPage, setRowsPerPage] = useState(10); // Tracks rows per page

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };
  const state = cityData;
  const containerRef = useRef(null);

  const handleServiceChange = (event) => {
    const valueAsBoolean = JSON.parse(event.target.value.toLowerCase());
    setServicerCreateAccountOption(valueAsBoolean);
    formik.setFieldValue("isServicer", valueAsBoolean);
  };

  const handleAccountChange = (event) => {
    const valueAsBoolean = JSON.parse(event.target.value.toLowerCase());
    setCreateAccount(valueAsBoolean);
    formik.setFieldValue("isAccountCreate", valueAsBoolean);
  };
  useEffect(() => {
    if (activeTab == "Settings") {
      dealerSettingData();
    }
  }, [activeTab, id.id, flag]);

  const handleTransitionEnd = () => {
    if (containerRef.current) {
      containerRef.current.classList.remove("scroll-transition");
      setScrolling(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    formik.resetForm();
  };

  const closeModal10 = () => {
    setModalOpen(false);
  };
  const carouselRef = useRef(null);

  useEffect(() => {
    getCovrageListData();
    setLoading(true);
    let intervalId;

    if (modalOpen && timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if (timer === 0) {
      closeModal10();
      closeModal1();
    }

    if (!modalOpen) {
      clearInterval(intervalId);
      setTimer(3);
    }

    setLoading(false);

    return () => {
      clearInterval(intervalId);
    };
  }, [modalOpen, timer]);

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    userValues.setFieldValue("status", selectedValue === "yes" ? true : false);
    setCreateAccountOption(selectedValue);
  };
  const handleRadio = (event) => {
    setShipping(event.target.value);
  };

  const getCovrageListData = async () => {
    try {
      const res = await getCovrageList();
      console.log(res);
      setCoverage(
        res.result.value.map((item) => ({
          label: item.label,
          value: item.value,
        }))
      );
    } catch (error) {
      console.error("Error fetching category list:", error);
    }
  };

  const getUserList = async () => {
    const result = await getUserListByDealerId(id.id, {});
    setRefreshUserList(result.result);
    // console.log(result, '------------------->>>>>')
    setSeparateAccountOption(result.userAccount);
  };

  const closeModal1 = () => {
    // setActiveTab("Servicer");
    setIsModalOpen1(false);
  };

  const modalOpen1 = () => {
    getServicerList();
    setActiveTab("Servicer");
    setIsModalOpen1(true);
  };

  const closeUserModal = () => {
    setIsUserModalOpen(false);
    setActiveTab("Users");
    localStorage.setItem("isPopupOpen", "false");
    userValues.resetForm();
  };

  const getServicerList = async () => {
    const result = await getServicerListForDealer(id.id);
    console.log(result)
    setServicerList(result.result);
  };

  useEffect(() => {
    dealerData();
    // getServicerListData()
    getServicerList();
  }, [id.id, flag]);

  useEffect(() => {
    getUserList();
  }, []);

  const dealerData = async (showLoader) => {
    if (!showLoader) {
      setLoading(true);
    }

    const result = await getDealersDetailsByid(id?.id);
    if (result.code == 200) {
      setDealerDetails(result.result[0]);
      setSelected(result.result[0].dealerData?.coverageType);
      setIsStatus(result?.result[0]?.dealerData.accountStatus);
      setInitialFormValues({
        accountName: result?.result[0]?.dealerData?.name,
        oldName: result?.result[0]?.dealerData?.name,
        dealerId: id.id,
        street: result?.result[0]?.dealerData?.street,
        city: result?.result[0]?.dealerData?.city,
        zip: result?.result[0]?.dealerData?.zip,
        state: result?.result[0]?.dealerData?.state,
        country: "USA",
      });
      setServicerCreateAccountOption(result?.result[0]?.dealerData?.isServicer);

      // console.log(result, '-------------->>>>>>')
      setCreateAccount(result?.result[0]?.dealerData?.isAccountCreate);
      setSelectedFile2(result?.result[0]?.dealerData?.termCondition);
      setCreateAccountOption(
        result?.result[0]?.dealerData?.isAccountCreate === false ? "no" : "yes"
      );
      setShipping(
        result?.result[0]?.dealerData?.isShippingAllowed === false
          ? "no"
          : "yes"
      );
    } else {
      navigate(`/`);
    }
    setLoading(false);
  };
  const dealerSettingData = async (showLoader) => {
    if (!showLoader) {
      setLoading(true);
    }

    const result = await getDealersSettingsByid(id?.id);
    if (result.code == 200) {
      console.log(result.result[0]);
      setDealerSettings(result.result[0]);
    } else {
      navigate(`/`);
    }
    setLoading(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const emailValidationRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const handleSelectChange = async (name, value) => {
    formik.setFieldValue(name, value);
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      dealerId: Yup.string().required("Required"),
      accountName: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required")
        .max(500, "Must be exactly 500 characters"),
      street: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required")
        .max(500, "Must be exactly 500 characters"),
      state: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required"),
      city: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required"),
      country: Yup.string().required("Required"),
      // serviceCoverageType: Yup.string()
      //   .transform((originalValue) => originalValue.trim())
      //   .required("Required"),
      // isShippingAllowed: Yup.string()
      //   .transform((originalValue) => originalValue.trim())
      //   .required("Required"),
      zip: Yup.string()
        .required("Required")
        .min(5, "Must be at least 5 characters")
        .max(6, "Must be exactly 6 characters"),
    }),

    onSubmit: async (values) => {
      // values.isShippingAllowed = shipping === "yes" ? true : false;

      setLoading(true);
      const result = await editDealerData(values);

      if (result.code == 200) {
        setLoading(false);
        setModalOpen(true);
        dealerData(true);
        setIsModalOpen(false);
        setFirstMessage("Updated Successfully");
        setSecondMessage("Dealer Updated Successfully");
        setTimer(3);

        setMessage("Dealer updated Successfully");
      } else if (result.message == "Account name is not available") {
        setLoading(false);
        formik.setFieldError("accountName", "Name Already Used");
      }
    },
  });


  const servicerForm = useFormik({
    initialValues: {
      selectedItems: [],
    },

    onSubmit: async (values) => {
      setLoading(true);
      const selectedData = servicerList.map((item) => ({
        _id: item._id,
        status: values.selectedItems.includes(item._id) || item.check,
      }));

      const result = await createRelationWithDealer(id.id, {
        servicers: selectedData,
      });

      if (result.code === 200) {
        setLoading(false);
        setFlagValue(true);
        setModalOpen(true);
        setFirstMessage("Servicer Updated Successfully");
        setSecondMessage("Servicer Updated Successfully");
        setTimer(3);
      } else {
        setLoading(false);
      }

      closeModal1();
      servicerForm.resetForm();
    },
  });

  const userValues = useFormik({
    initialValues: initialUserFormValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstName: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required")
        .max(500, "Must be exactly 500 characters"),
      lastName: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .required("Required")
        .max(500, "Must be exactly 500 characters"),
      phoneNumber: Yup.string()
        .required("Required")
        .min(10, "Must be at least 10 characters")
        .max(10, "Must be exactly 10 characters")
        .matches(/^[0-9]+$/, "Must contain only digits"),
      email: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .matches(emailValidationRegex, "Invalid email address")
        .required("Required"),
    }),

    onSubmit: async (values, { setFieldError }) => {
      localStorage.setItem("menu", "Users");

      values.status = createAccountOption;
      if (values.status === "yes") {
        values.status = true;
      } else if (values.status === "no") {
        values.status = false;
      }
      setLoading(true);
      const result = await addUserByDealerId(values);

      if (result.code == 200) {
        getUserList();
        dealerData();
        setModalOpen(true);
        setFirstMessage("New User Added Successfully");
        setSecondMessage("New User Added Successfully");
        setMessage("Dealer updated Successfully");
        setLoading(false);
        closeUserModal();
        setTimer(3);
        setActiveTab("Users");
      } else {
        if (result.code === 401) {
          setFieldError("email", "Email already in use");
        }
        setLoading(false);
      }
      localStorage.setItem("menu", "Users");
    },
  });

  const openUserModal = () => {
    userValues.resetForm();
    setActiveTab("Users123");
    localStorage.setItem("isPopupOpen", "true");
    setIsUserModalOpen(true);
  };

  useEffect(() => {
    const isPopupOpen = localStorage.getItem("isPopupOpen") === "true";
    if (isPopupOpen) {
      setActiveTab("Users");
    }
  }, []);

  useEffect(() => {
    checkTokenExpiry();
    localStorage.setItem("menu", activeTab);
    if (!createServicerAccountOption) {
      if (
        activeTab === "Customer" ||
        activeTab === "Users" ||
        activeTab === "PriceBook" ||
        activeTab === "Settings" ||
        activeTab === "Paid Claims" ||
        activeTab === "Unpaid Claims"
      ) {
        if (carouselRef.current) {
          carouselRef.current.next(4);
        }
      }
    } else {
      if (
        activeTab === "Customer" ||
        activeTab === "Users" ||
        activeTab === "PriceBook" ||
        activeTab === "Settings" ||
        activeTab === "Paid Claims" ||
        activeTab === "Unpaid Claims"
      ) {
        if (carouselRef.current) {
          carouselRef.current.next(5);
        }
      }
    }
  }, [activeTab, carouselRef, createServicerAccountOption]);

  const checkTokenExpiry = async () => {
    try {
      const response = await checkUserToken();
      console.log(response.code == 200);
      if (response.code == 200) {
        return;
      } else {
        navigate(`/`);
        localStorage.removeItem("userDetails");
      }
    } catch (error) {
      navigate(`/`);
      localStorage.removeItem("userDetails");
    } finally {
    }
  };
  const columns = [
    {
      name: "Serial #",
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
      sortable: true,
      minWidth: "33%",
      center: true,
    },
    {
      name: "Servicer Name",
      selector: (row) => row.name,
      sortable: true,
      minWidth: "50%",
      center: true,
    },
    {
      name: "Action",
      center: true,
      minWidth: "12%",
      cell: (row, index) => {
        return (
          <div>
            <input
              type="checkbox"
              className="accent-gray-600"
              checked={row.check}
              onChange={(e) => {
                const checked = e.target.checked;
                const itemId = servicerList[index]._id;
                servicerList[index].check = checked;
                const selectedItems = checked
                  ? [...servicerForm.values.selectedItems, itemId]
                  : servicerForm.values.selectedItems.filter(
                    (id) => id !== itemId
                  );

                servicerForm.setFieldValue("selectedItems", selectedItems);
              }}
            />
          </div>
        );
      },
    },
  ];

  const CustomNoDataComponent = () => (
    <Card className="text-center">
      <p>No records found.</p>
    </Card>
  );

  const tabs = [
    {
      id: "Orders",
      label: "Orders",
      icons: Order,
      Activeicons: OrderActive,
      content: activeTab === "Orders" && (
        <OrderList id={id.id} flag={"dealer"} activeTab={activeTab} />
      ),
    },
    {
      id: "Contracts",
      label: "Contracts",
      icons: Contract,
      Activeicons: ContractsActive,
      content: activeTab === "Contracts" && (
        <ContractList id={id.id} flag={"dealer"} />
      ),
    },
    {
      id: "Claims",
      label: "Claims",
      icons: Claim,
      Activeicons: ClaimActive,
      content: activeTab === "Claims" && (
        <ClaimList id={id.id} flag={"dealer"} />
      ),
    },
    {
      id: "Reseller",
      label: "Reseller",
      icons: User,
      Activeicons: UserActive,
      content: activeTab === "Reseller" && (
        <Reseller id={id.id} activeTab={activeTab} />
      ),
    },
    {
      id: "Servicer",
      label: "Servicer",
      icons: Servicer,
      Activeicons: ServicerActive,
      content: activeTab === "Servicer" && (
        <ServicerList id={id.id} flag={flagValue} activeTab={activeTab} />
      ),
    },
    {
      id: "Customer",
      label: "Customer",
      icons: Customer,
      Activeicons: CustomerActive,
      content: activeTab === "Customer" && (
        <CustomerList id={id.id} activeTab={activeTab} />
      ),
    },
    {
      id: "Users",
      label: "Users",
      icons: User,
      Activeicons: UserActive,
      content: <UserList flag={"dealer"} id={id.id} activeTab={activeTab} setLoading={setLoading} />,
    },
    {
      id: "PriceBook",
      label: "PriceBook",
      icons: PriceBook,
      Activeicons: PriceBookActive,
      content: activeTab === "PriceBook" && (
        <PriceBookList id={id.id} activeTab={activeTab} />
      ),
    },
    {
      id: "Settings",
      label: "Settings",
      icons: PriceBook,
      Activeicons: PriceBookActive,
      content: activeTab === "Settings" && (
        <Setting dealerDetails={dealerSettings} />
      ),
    },
  ];

  if (createServicerAccountOption === true) {
    tabs.push(
      {
        id: "Unpaid Claims",
        label: "Unpaid Claims",
        icons: Unpaid,
        Activeicons: UnpaidActive,
        content: activeTab === "Unpaid Claims" && (
          <ClaimList12 id={id.id} flag="dealer" activeTab={activeTab} />
        ),
      },
      {
        id: "Paid Claims",
        label: "Paid Claims",
        icons: Paid,
        Activeicons: ActivePaid,
        content: activeTab === "Paid Claims" && (
          <ClaimList12 id={id.id} flag="dealer" activeTab={activeTab} />
        ),
      }
    );
  }

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const routeToPage = (data) => {
    switch (data) {
      case "PriceBook":
        localStorage.setItem("menu", "PriceBook");
        navigate(`/addPriceBook/${id.id}`);
        break;
      case "Customer":
        localStorage.setItem("menu", "Customer");
        navigate(`/addCustomer/${id.id}`);
        break;
      case "Users":
        openUserModal();
        break;
      case "Servicer":
        modalOpen1();
        break;
      case "Reseller":
        localStorage.setItem("menu", "Reseller");
        navigate(`/addReseller/${id.id}`);
        break;
      case "Orders":
        localStorage.setItem("menu", "Orders");
        navigate(`/addOrder/${id.id}`);
        break;
      case "Claims":
        localStorage.setItem("menu", "Claims");
        navigate(`/addClaim`);
        break;

      default:
      // console.log("Invalid data, no navigation");
    }
  };

  const formatOrderValue = (orderValue) => {
    if (Math.abs(orderValue) >= 1e6) {
      return (orderValue / 1e6).toFixed(2) + "M";
    } else {
      return orderValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ("" + phoneNumber).replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // Match groups of 3 digits

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber; // Return original phone number if it couldn't be formatted
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 5, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const handleSelectChange1 = (name, value) => {
    formik.setFieldValue(name, value);
  };

  const serviceCoverage = [
    { label: "Parts", value: "Parts" },
    { label: "Labor ", value: "Labour" },
    { label: "Parts & Labor ", value: "Parts & Labour" },
  ];

  const [buttonTextColor, setButtonTextColor] = useState("");
  const [backGroundColor, setBackGroundColor] = useState("");

  useEffect(() => {
    const storedUserDetails = getUserDetailsFromLocalStorage();

    if (storedUserDetails) {
      const colorScheme = storedUserDetails.colorScheme;
      colorScheme?.forEach((color) => {
        switch (color.colorType) {
          case "inActiveButtonColor":
            setBackGroundColor(color.colorCode);
            break;
          case "buttonTextColor":
            setButtonTextColor(color.colorCode);
            break;
          default:
            break;
        }
      });
    }
  }, []);

  const InactiveTabButton = ({ tab, onClick }) => (
    <InActiveButton
      className="flex self-center mr-2 w-[95%] !px-2 !py-1 rounded-xl border-[1px] border-Light-Grey "
      onClick={onClick}
    >
      <div
        style={{
          maskImage: `url(${tab.icons})`,
          WebkitMaskImage: `url(${tab.icons})`,
          maskRepeat: "no-repeat",
          WebkitMaskRepeat: "no-repeat",
          maskPosition: "center",
          WebkitMaskPosition: "center",
          maskSize: "contain",
          WebkitMaskSize: "contain",
        }}
        className="self-center pr-1 py-1 h-4 w-4"
      />
      <span
        style={{
          borderLeftWidth: "1px",
          paddingLeft: "7px",
        }}
        className="ml-1 py-1 text-sm font-Regular"
      >
        {tab.label}
      </span>
    </InActiveButton>
  );

  // ActiveTabButton Component
  const ActiveTabButton = ({ tab, onClick }) => (
    <Button
      className="flex self-center mr-2 w-[95%] !px-2 !py-1 rounded-xl border-[1px] border-Light-Grey"
      onClick={onClick}
    >
      <div
        style={{
          maskImage: `url(${tab.Activeicons})`,
          WebkitMaskImage: `url(${tab.Activeicons})`,
          backgroundColor: buttonTextColor,
          maskRepeat: "no-repeat",
          WebkitMaskRepeat: "no-repeat",
          maskPosition: "center",
          WebkitMaskPosition: "center",
          maskSize: "contain",
          WebkitMaskSize: "contain",
        }}
        className="self-center pr-1 py-1 h-4 w-4"
      />
      <span
        style={{
          borderColor: buttonTextColor,
          borderLeftWidth: "1px",
          paddingLeft: "7px",
          color: buttonTextColor,
        }}
        className="ml-1 py-1 text-sm font-Regular"
      >
        {tab.label}
      </span>
    </Button>
  );
  return (
    <>
      {loading && (
        <div className=" fixed z-[999999] bg-[#333333c7] backdrop-blur-xl  h-screen w-full flex py-5">
          <div className="self-center mx-auto">
            <RotateLoader color="#fff" />
          </div>
        </div>
      )}

      <div className="py-8 pl-3 relative ">
        <Headbar />

        <div className="flex">
          <div onClick={() => localStorage.removeItem("menu")}>
            <Link
              to={"/dealerList"}
              className="h-[60px] w-[60px] flex border-[1px] bg-white border-Light-Grey rounded-[25px]"
            >
              <img
                src={BackImage}
                className="m-auto my-auto self-center bg-white"
                alt="BackImage"
              />
            </Link>
          </div>
          <div className="pl-3">
            <p className="font-bold text-[36px] leading-9 mb-[3px]">
              Dealer Details
            </p>
            <ul className="flex self-center">
              <li className="text-sm  font-Regular">
                <Link to={"/"}>Home / </Link>{" "}
              </li>
              <li className="text-sm  ml-1 font-Regular">
                <Link to={"/dealerList"}> Dealer List / </Link>{" "}
              </li>
              <li className="text-sm  font-semibold ml-1 pt-[1px]">
                {" "}
                Dealer Detail ({activeTab})
              </li>
            </ul>
          </div>
        </div>

        <Grid className="!grid-cols-4 mt-5">
          <div className="col-span-1 max-h-[85vh] overflow-y-scroll">
            <SingleView className="   p-5 rounded-[20px]">
              <Grid>
                <div className="col-span-9">
                  <p className="text-sm font-Regular">
                    Account Name
                  </p>
                  <p className="text-xl font-semibold break-words">
                    {dealerDetails?.dealerData?.name}
                  </p>
                </div>
                <div className="col-span-3 text-end">
                  <Button
                    className="border !border-Bright-Grey !text-sm !font-Regular"
                    onClick={openModal}
                  >
                    Edit
                  </Button>
                </div>
              </Grid>
              <div className="flex my-4">
                <img
                  src={address}
                  className="mr-3 bg-Onyx rounded-[14px] my-auto"
                  alt="Address"
                />
                <div>
                  <p className="text-sm font-Regular mt-3">
                    Address
                  </p>
                  <p className="text-base font-semibold leading-5">
                    {dealerDetails?.dealerData?.street},{" "}
                    {dealerDetails?.dealerData?.city},{" "}
                    {dealerDetails?.dealerData?.state},{" "}
                    {dealerDetails?.dealerData?.zip}, USA
                  </p>
                </div>
              </div>
              <div className="flex w-full my-4">
                <p className="text-[10px] mr-3 font-Regular">
                  PRIMARY CONTACT DETAILS
                </p>
                <hr className="self-center border-[#999999] w-[40%]" />
              </div>
              <div className="flex mb-4">
                <img
                  src={name}
                  className="mr-3 bg-Onyx rounded-[14px]"
                  alt="Name"
                />
                <div>
                  <p className="text-sm font-Regular">Name</p>
                  <p className="text-base font-semibold ">
                    {dealerDetails?.firstName} {dealerDetails?.lastName}
                  </p>
                </div>
              </div>
              <div className="flex mb-4">
                <img
                  src={email}
                  className="mr-3 bg-Onyx rounded-[14px]"
                  alt="email"
                />
                <div className="w-[80%]">
                  <p className="text-sm font-Regular">
                    Email
                  </p>
                  <p className="text-base leading-[13px] font-semibold break-words">
                    {dealerDetails?.email}
                  </p>
                </div>
              </div>
              <div className="flex mb-4">
                <img
                  src={phone}
                  className="mr-3 bg-Onyx rounded-[14px]"
                  alt="name"
                />
                <div>
                  <p className="text-sm font-Regular">
                    Phone Number
                  </p>
                  <p className="text-base  font-semibold ">
                    +1 {formatPhoneNumber(dealerDetails?.phoneNumber)}
                  </p>
                </div>
              </div>
              <Grid className="mt-5">
                <div className="col-span-6 ">
                  <div className="bg-[#2A2A2A] self-center px-4 py-6 rounded-xl">
                    <p className="text-white text-lg font-semibold ">
                      {dealerDetails?.ordersResult?.[0]?.noOfOrders ?? 0}
                    </p>
                    <p className="text-neutral-grey text-sm font-Regular ">
                      Total Number of Orders
                    </p>
                  </div>
                </div>
                <div className="col-span-6 ">
                  <div className="bg-[#2A2A2A] self-center px-4 py-6 rounded-xl">
                    <p className="text-white text-lg  !font-[600]">
                      $
                      {formatOrderValue(
                        dealerDetails?.ordersResult?.[0]?.orderAmount ??
                        parseInt(0)
                      )}
                    </p>
                    <p className="text-neutral-grey text-sm font-Regular">
                      Total Value of Orders
                    </p>
                  </div>
                </div>
                <div className="col-span-6 ">
                  <div className="bg-[#2A2A2A] self-center px-4 py-6 rounded-xl">
                    <p className="text-white text-lg !font-[600]">
                      {dealerDetails?.claimData?.numberOfClaims ?? 0}
                    </p>
                    <p className="text-neutral-grey text-sm font-Regular">
                      Total number of Claims
                    </p>
                  </div>
                </div>
                <div className="col-span-6 ">
                  <div className="bg-[#2A2A2A] self-center px-4 py-6 rounded-xl">
                    <p className="text-white text-lg  !font-[600]">
                      {" "}
                      $
                      {formatOrderValue(
                        dealerDetails?.claimData?.valueClaim ?? parseInt(0)
                      )}
                    </p>
                    <p className="text-neutral-grey text-sm font-Regular">
                      Total Value of Claims
                    </p>
                  </div>
                </div>
              </Grid>
            </SingleView>
          </div>
          <div className="col-span-3 max-h-[85vh] pr-3 overflow-y-scroll">
            <Grid className="!gap-2">
              <div
                className={` ${isStatus == true
                  ? "col-span-10 relative"
                  : "col-span-10 mr-[30px] relative"
                  }`}
              >
                <div
                  className={` rounded-[30px] px-2 py-3 border-[1px] border-Light-Grey`}
                  ref={containerRef}
                  onTransitionEnd={handleTransitionEnd}
                >
                  <Carousel className="!gap-1" ssr={true} ref={carouselRef} responsive={responsive} containerClass="carousel">
                    {tabs.map((tab) =>
                      activeTab === tab.id ? (
                        <ActiveTabButton key={tab.id} tab={tab} onClick={() => handleTabClick(tab.id)} />
                      ) : (
                        <InactiveTabButton key={tab.id} tab={tab} onClick={() => handleTabClick(tab.id)} />
                      )
                    )}
                  </Carousel>
                  <div className="absolute h-full  right-[-15px] flex top-0 self-center">
                    {" "}
                  </div>
                </div>
              </div>

              {isStatus == true ? (
                <>
                  {activeTab !== "Contracts" &&
                    activeTab !== "Unpaid Claims" &&
                    activeTab !== "Settings" &&
                    activeTab !== "Paid Claims" ? (
                    <div
                      className="col-span-2 self-center"
                      onClick={() => routeToPage(activeTab)}
                    >
                      <InActiveButton className=" flex self-center h-[60px] rounded-xl ml-auto border-[1px] border-Light-Grey">

                        <div
                          style={{
                            maskImage: `url(${AddItem})`,
                            WebkitMaskImage: `url(${AddItem})`,
                            maskRepeat: "no-repeat",
                            WebkitMaskRepeat: "no-repeat",
                            maskPosition: "center",
                            WebkitMaskPosition: "center",
                            maskSize: "contain",
                            WebkitMaskSize: "contain",
                          }}
                          className="self-center pr-1 py-1 h-4 w-4"
                        />
                        <span
                          style={{
                            borderLeftWidth: "1px",
                            paddingLeft: "7px",
                          }}
                          className=" ml-1 text-[13px] self-center font-Regular !font-[700]"
                        >
                          {activeTab === "Servicer" ? "Assign " : "Add "}{" "}
                          {activeTab}
                        </span>
                      </InActiveButton>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {activeTab === "Claims" ? (
                    <div
                      className="col-span-2 self-center"
                      onClick={() => routeToPage(activeTab)}
                    >
                      <Button className="!bg-white flex self-center h-[60px] rounded-xl ml-auto border-[1px] border-Light-Grey">
                        {" "}
                        <img
                          src={AddItem}
                          className="self-center"
                          alt="AddItem"
                        />{" "}
                        <span className="text-black ml-1 text-[13px] self-center font-Regular !font-[700]">
                          {activeTab === "Servicer" ? "Assign " : "Add "}{" "}
                          {activeTab}
                        </span>{" "}
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Grid>
            {tabs.map((tab) => (
              <div
                key={tab.id}
                className={`${activeTab !== tab.id ? "hidden" : "pb-20"}`}
              >
                {tab.content}
              </div>
            ))}
          </div>
        </Grid>
      </div>

      {/* Modal Email Popop */}
      <Modal isOpen={isModalOpen} className="!w-[730px]" onClose={closeModal}>
        <div className="px-8 py-4">
          <p className="text-3xl text-center font-semibold mb-4">
            Edit Dealer Details
          </p>
          <form className="mt-4" onSubmit={formik.handleSubmit}>
            <Grid>
              <div className="col-span-6">
                <Input
                  type="text"
                  name="accountName"
                  className="!bg-white"
                  label="Account Name"
                  required={true}
                  placeholder=""
                  maxLength={"500"}
                  value={formik.values.accountName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.accountName && formik.errors.accountName
                  }
                />
                {formik.touched.accountName && formik.errors.accountName && (
                  <div className="text-red-500 text-sm pl-2 pt-2">
                    {formik.errors.accountName}
                  </div>
                )}
              </div>
              <div className="col-span-6">
                <Input
                  type="text"
                  name="street"
                  className="!bg-white"
                  label="Street Address"
                  required={true}
                  placeholder=""
                  maxLength={"500"}
                  value={formik.values.street}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.street && formik.errors.street}
                />
                {formik.touched.street && formik.errors.street && (
                  <div className="text-red-500 text-sm pl-2 pt-2">
                    {formik.errors.street}
                  </div>
                )}
              </div>
              <div className="col-span-6">
                <Input
                  type="number"
                  name="zip"
                  label="Zip Code"
                  className="!bg-white"
                  required={true}
                  placeholder=""
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minLength={"5"}
                  maxLength={"6"}
                  error={formik.touched.zip && formik.errors.zip}
                />
                {formik.touched.zip && formik.errors.zip && (
                  <div className="text-red-500 text-sm pl-2 pt-2">
                    {formik.errors.zip}
                  </div>
                )}
              </div>
              <div className="col-span-6">
                <Input
                  type="text"
                  name="city"
                  label="City"
                  className="!bg-white"
                  placeholder=" "
                  required={true}
                  maxLength={"20"}
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && formik.errors.city}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="text-red-500 text-sm pl-2 pt-2">
                    {formik.errors.city}
                  </div>
                )}
              </div>
              <div className="col-span-6">
                <Select
                  label="State"
                  name="state"
                  placeholder=""
                  className="!bg-white"
                  required={true}
                  onChange={handleSelectChange}
                  options={state}
                  value={formik.values.state}
                  onBlur={formik.handleBlur}
                  error={formik.touched.state && formik.errors.state}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className="text-red-500 text-sm pl-2 pt-2">
                    {formik.errors.state}
                  </div>
                )}
              </div>
              <div className="col-span-6">
                <Input
                  type="text"
                  name="country"
                  label="Country"
                  className="!bg-white"
                  required={true}
                  placeholder=""
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  defaultValue="USA"
                  error={formik.touched.country && formik.errors.country}
                  disabled
                />
              </div>
              <div className="col-span-4">
                <InActiveButton
                  type="button"
                  className="border w-full !text-sm !font-Regular"
                  onClick={closeModal}
                >
                  Cancel
                </InActiveButton>
              </div>
              <div className="col-span-8">
                <Button type="submit" className="w-full">
                  Submit
                </Button>
              </div>
            </Grid>
          </form>
        </div>
      </Modal>

      {/* Modal Primary Popop */}
      <Modal isOpen={isModalOpen1} onClose={closeModal1}>
        <form onSubmit={servicerForm.handleSubmit}>
          <div className="text-center py-3">
            <p className="text-3xl mb-0 mt-2 font-bold">
              Assign Servicer
            </p>
            <div className="my-4 h-[350px] max-h-[350px] overflow-y-scroll">
              <DataTable
                columns={columns}
                data={servicerList}
                highlightOnHover
                draggableColumns={false}
                sortIcon={
                  <>
                    {" "}
                    <div
                      style={{
                        maskImage: `url(${shorting})`,
                        WebkitMaskImage: `url(${shorting})`,
                        maskRepeat: "no-repeat",
                        WebkitMaskRepeat: "no-repeat",
                        maskPosition: "center",
                        WebkitMaskPosition: "center",
                        maskSize: "contain",
                        WebkitMaskSize: "contain",
                      }}
                      className="ml-2 tabless"
                    />
                    {/* <img src={shorting} className="ml-2" alt="shorting" /> */}
                  </>
                }
                noDataComponent={<CustomNoDataComponent />}
              />
            </div>
            <Grid className="drop-shadow-5xl">
              <div className="col-span-4">
                <InActiveButton
                  type="button"
                  className="border w-full !text-sm !font-Regular"
                  onClick={closeModal1}
                >
                  Cancel
                </InActiveButton>
              </div>
              <div className="col-span-8">
                <Button type="submit" className="w-full">
                  Submit
                </Button>
              </div>
            </Grid>
          </div>
        </form>
      </Modal>

      {/* Modal Add User Popop */}
      <Modal isOpen={isUserModalOpen} onClose={closeUserModal}>
        <div className=" py-3">
          <p className=" text-center text-3xl mb-5 mt-2 font-bold">
            Add New User
          </p>
          <form onSubmit={userValues.handleSubmit}>
            <Grid className="px-8">
              <div className="col-span-6">
                <Input
                  type="text"
                  name="firstName"
                  label="First Name"
                  required={true}
                  placeholder=""
                  className="!bg-white"
                  maxLength={"30"}
                  value={userValues.values.firstName}
                  onBlur={userValues.handleBlur}
                  onChange={userValues.handleChange}
                  error={
                    userValues.touched.firstName && userValues.errors.firstName
                  }
                />
                {userValues.touched.firstName &&
                  userValues.errors.firstName && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {userValues.errors.firstName}
                    </div>
                  )}
              </div>
              <div className="col-span-6">
                <Input
                  type="text"
                  name="lastName"
                  label="Last Name"
                  required={true}
                  placeholder=""
                  className="!bg-white"
                  maxLength={"30"}
                  value={userValues.values.lastName}
                  onBlur={userValues.handleBlur}
                  onChange={userValues.handleChange}
                  error={
                    userValues.touched.lastName && userValues.errors.lastName
                  }
                />
                {userValues.touched.lastName && userValues.errors.lastName && (
                  <div className="text-red-500 text-sm pl-2 pt-2">
                    {userValues.errors.lastName}
                  </div>
                )}
              </div>
              <div className="col-span-6">
                <Input
                  type="text"
                  name="email"
                  label="Email"
                  placeholder=""
                  className="!bg-white"
                  required={true}
                  value={userValues.values.email}
                  onBlur={userValues.handleBlur}
                  onChange={userValues.handleChange}
                  error={userValues.touched.email && userValues.errors.email}
                />
                {userValues.touched.email && userValues.errors.email && (
                  <div className="text-red-500 text-sm pl-2 pt-2">
                    {userValues.errors.email}
                  </div>
                )}
              </div>
              <div className="col-span-6">
                <Input
                  type="tel"
                  name="phoneNumber"
                  label="Phone"
                  nonumber={true}
                  required={true}
                  className="!bg-white"
                  placeholder=""
                  value={userValues.values.phoneNumber}
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(
                      /[^0-9]/g,
                      ""
                    );

                    userValues.handleChange({
                      target: {
                        name: "phoneNumber",
                        value: sanitizedValue,
                      },
                    });
                  }}
                  onBlur={userValues.handleBlur}
                  minLength={"10"}
                  maxLength={"10"}
                  error={
                    userValues.touched.phoneNumber &&
                    userValues.errors.phoneNumber
                  }
                />
                {(userValues.touched.phoneNumber ||
                  userValues.submitCount > 0) &&
                  userValues.errors.phoneNumber && (
                    <div className="text-red-500 text-sm pl-2 pt-2">
                      {userValues.errors.phoneNumber}
                    </div>
                  )}
              </div>
              <div className="col-span-6">
                <Input
                  type="text"
                  name="position"
                  label="Position"
                  className="!bg-white"
                  placeholder=""
                  maxLength={"50"}
                  value={userValues.values.position}
                  onBlur={userValues.handleBlur}
                  onChange={userValues.handleChange}
                  error={
                    userValues.touched.position && userValues.errors.position
                  }
                />
              </div>
              <div className="col-span-6">
                <p className=" flex text-[12px] font-semibold mt-3 mb-6">
                  Do you want to create an account?
                  <RadioButton
                    id="yes-create-account"
                    label="Yes"
                    value="yes"
                    disabled={
                      dealerDetails.dealerData?.isAccountCreate === false
                    }
                    checked={createAccountOption === "yes"}
                    onChange={handleRadioChange}
                  />
                  <RadioButton
                    id="no-create-account"
                    label="No"
                    value="no"
                    checked={createAccountOption === "no"}
                    disabled={
                      dealerDetails.dealerData?.isAccountCreate === false
                    }
                    onChange={handleRadioChange}
                  />
                </p>
              </div>
            </Grid>
            <Grid className="drop-shadow-5xl px-8">
              <div className="col-span-4">
                <InActiveButton
                  type="button"
                  className="border w-full !text-sm !font-Regular"
                  onClick={closeUserModal}
                >
                  Cancel
                </InActiveButton>
              </div>
              <div className="col-span-8">
                <Button type="submit" className="w-full">
                  Submit
                </Button>
              </div>
            </Grid>
          </form>
        </div>
      </Modal>

      {/* Modal Detail Popop */}
      <Modal isOpen={modalOpen} onClose={closeModal10}>
        <div className="text-center py-3">
          <img src={Primary} alt="email Image" className="mx-auto" />
          <p className="text-3xl mb-0 mt-2 font-bold">
            {firstMessage}
          </p>
          <p className=" text-base font-medium mt-4">
            {secondMessage} {""} <br /> Redirecting Back to Detail page in{" "}
            {timer} Seconds
          </p>
        </div>
      </Modal>
    </>
  );
}

export default DealerDetails;
